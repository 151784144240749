@use '../../../common/styles/mixins';

.gameContainer {
    --margin-top: 24px;

    .game {
        @include mixins.gameGroup();

        .nameContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .gameName {
                @include mixins.gameCategoryName();
            }

            .controls {
                display: flex;
                gap: 24px;

                img {
                    cursor: pointer;
                }
            }
        }

        .iframe {
            margin-top: var(--margin-top);
            width: 100%;
            aspect-ratio: 16 / 9;

            .buttonsContainer {
                height: 100%;
                @include mixins.flexCenter();

                button {
                    width: 250px;
                    height: 50px;
                    border: none;
                    font-weight: 600;
                    border-radius: var(--radius-12);

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }

                .buttonDemo {
                    background-color: var(--background-surface-default);
                    color: var(--background-button-primary);
                }

                .buttonSignUp {
                    background-color: var(--background-button-primary);
                }
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .tabsContainer {
            width: 100%;
            display: flex;
            justify-content: center;

            .tabs {
                margin-top: var(--margin-top);
                width: fit-content;
            }
        }
    }

    .gameInfoErrorBlock{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

