@use '../../../common/styles/mixins';

.component {
  height: 324px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(92deg, #E49B07 0%, #DB3AAC 50%, #216BEF 100%);
  border-radius: var(--radius-40);

  .firstContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    
    .registrationContainer {
      display: flex;
      align-items: center;

      .imageContainer {
        width: 44px;
        height: 44px;
        border-radius: var(--radius-12);
        background-color: rgba(255, 255, 255, 0.25);
        @include mixins.flexCenter();
        margin-right: 12px;
      }

      .registrationText {
        font-size: var(--numbers-24);
        font-weight: 700;
        line-height: var(--numbers-32);
        letter-spacing: -0.48px;
      }
    }

    .jackpotText {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      width: 526px;
      text-align: center;
    }
  }


}