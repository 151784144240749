@use '../../../../common/styles/mixins';

@mixin currencyButton() {
    background-color: var(--background-surface-elevation-2);
    border: 1px solid var(--border-base-default);

    @include mixins.buttonGlassHoverActive(); /*Done same styles hover, active as for glass button*/
}

@mixin currencyButtonSelected() {
    .currencyButtonSelected {
        border: 1px solid var(--border-accent-brand-accent);
    }
}

.currencyAndPayments {
    .button {
        width: 100%;
    }

    .moreButton {
        margin-top: 16px;
    }


    .buttonGroup {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .navigationButton {
            border-radius: var(--radius-100);
        }

        .buttonContent {
            display: flex;

            .imgWithMargin {
                margin-left: 6px;
            }
        }
    }

    .currencyContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 24px 0;

        .currencyElement {
            background: var(--background-surface-elevation-1);
            padding: 16px;
            width: 100%;
            border-radius: var(--radius-24);

            .currencyText {
                color: var(--text-base-primary);
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: -0.48px;
            }

            .balance {
                margin-top: 8px;
                margin-bottom: 24px;
                display: flex;

                .name, .amount {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                }

                .name {
                    color: var(--text-base-primary);
                }

                .amount {
                    color: var(--text-base-secondary);
                    margin-left: 2px;
                }
            }

            .buttonGroup {
                gap: 6px;

                .currencyButton {
                    @include currencyButton();
                    color: var(--text-base-primary);
                    height: 48px;
                    border-radius: var(--radius-100);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 18px;
                    min-width: 85px; /* Минимальная ширина кнопки */

                }

                @include currencyButtonSelected();

                .currencyCode {
                    display: flex;
                    gap: 4px;
                    justify-content: center;
                }
            }

            .paymentButtonsGroup {
                .currencyButtonsGroup {
                    display: flex;
                    gap: 8px;

                    .currencyButton {
                        @include currencyButton();
                        width: 98px;
                        height: 56px;
                        border-radius: var(--radius-16);

                        .paymentMethodImg {
                            width: 86px;
                            height: 40px;
                        }
                    }

                    @include currencyButtonSelected();
                }



                @include currencyButtonSelected();
            }
        }
    }

    .promoContainer {
        display: flex;
        justify-content: center;

        .promo {
            cursor: pointer;
            color: var(--text-base-secondary);
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
            margin-top: 16px;
            width: fit-content;
        }
    }

}

.currencyButton:hover,
.paymentMethodButton:hover {
    border-color: #28a745;
    /* Зеленый бордер при наведении */
}

.currencyButton.border2,
.paymentMethodButton.border2 {
    border-width: 3px;
    /* Зеленый бордер при наведении */
}

.buttonBgTransparent {
    border-width: 2px;
    background-color: transparent;
}

/* Основной стиль для круга */
.checkmarkCircle {
    display: inline-block;
    width: 1.2em;
    /* Устанавливаем ширину */
    height: 1.2em;
    /* Устанавливаем высоту */
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
}

/* Псевдоэлемент для галочки (скрыт по умолчанию) */
.checkmarkCircle::after {
    content: '';
    display: block;
    width: 0.5em;
    height: 0.5em;
    border: solid white;
    border-width: 0 3px 3px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: opacity 0.3s;
}

/* Состояние с прозрачным кругом и белой рамкой с галочкой */
.checkmarkCircle.active {
    background-color: transparent;
    border: 2px solid white;
}

/* Показываем галочку, когда добавлен класс .checked */
.checkmarkCircle.active::after {
    opacity: 1;
}

.checkmarkCircle.inactive {
    background-color: white;
    border: 2px solid #6c757d;
}

/* Показываем галочку, когда добавлен класс .checked */
.checkmarkCircle.inactive::after {
    opacity: 1;
}


/* AlertStyles.module.css */
.alertBorderSuccess {
    border: 2px solid #28a745;
    /* Зеленый */
    border-radius: 5px;
}

.alertBorderError {
    border: 2px solid #dc3545;
    /* Красный */
    border-radius: 5px;
}

.alertBorderWarning {
    border: 2px solid #ffc107;
    /* Желтый */
    border-radius: 5px;
}

.alertBorderInfo {
    border: 2px solid #17a2b8;
    /* Голубой */
    border-radius: 5px;
}

.alertBorderSecondary {
    border: 2px solid #6c757d;
    /* Голубой */
    border-radius: 5px;
}