@use '../../../../common/styles/mixins';

.carousel {
    .element {
        width: 134px;
        height: 88px;
        @include mixins.carouselElement();
        @include mixins.flexCenter();
    }
}

.carouselSettings {
    @include mixins.carouselSettings();
}