.navigation {
    --navbar-gap: 24px;

    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .navbar {
            display: flex;
            align-items: center;
            gap: var(--navbar-gap);
        }

        .specialMarginAuthenticated {
            margin-left: calc(-1 * var(--navbar-gap)); // remove union gap
            padding-left: 32px;
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .registerButtonSpecialMargin {
            margin-left: calc(-1 * var(--navbar-gap)); // remove union gap also, but this time for left & right
            margin-right: calc(-1 * var(--navbar-gap));
            padding-left: 12px;
            padding-right: 24px;
        }

        .logoSearch {
            display: flex;

            .search {
                margin-left: 91px;
            }
        }
    }

    .userIcon {
        height: 40px;
        min-width: 40px;
        cursor: pointer;
    }

    .tempStar {
        font-size: 24px;
        cursor: pointer;
    }
}


