@use '../../../common/styles/mixins';

.gameGroups {
    height: 88px;

    .groupsContainer {
        width: 100%;
        background-color: var(--background-surface-elevation-1);
        padding: 0 12px;
        border-radius: 70px;
    }

    .element {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--background-surface-elevation-2);
        height: 64px;
        border-radius: var(--radius-16);
        font-size: 20px;
        @include mixins.flexCenter();
        padding: 16px 24px;

        .gameIcon {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .groupName {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.4px;
        }
    }
}

.carousel {
    height: 88px;
    display: flex;
    align-items: center;
    gap: 6px;
}