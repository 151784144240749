.component {
    color: var(--text-base-primary);

    .paySystem {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        align-items: center;
        gap: 8px;

        .currencyImg {
            
        }

        .paySystemLogo {
            width: 88px;
            height: 40px;
        }
    }


.submitButton {
    width: 100%;
    margin-top: 16px;
}

.amountButtonsGroup {
    display: flex;
    gap: 8px;
    margin-top: 24px;

    .amountButton {
        width: 100%;
        border-radius: var(--radius-8);
    }
}

.carousel {
    position: relative;
    display: flex;
    gap: 10px; // changed from figma 16px to fit the design // todo: need to understand why
    height: 180px;

    .carouselControls {
        position: absolute;
        display: flex;
        gap: 10px;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 1;
        width: 100%;
        justify-content: space-between;
    }

    .bonusCard {
        position: relative;
        width: 200px;
        min-width: 200px;
        height: 100%;
        border: 2px solid var(--border-base-default);
        border-radius: var(--radius-24);
        background: var(--background-surface-elevation-1);
        padding: 16px;
        cursor: pointer;
        overflow: hidden;

        .bonusHeader, .bonusDescription {
            text-wrap: wrap;
            word-wrap: break-word;
        }


        .bonusHeader {
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 12px;
        }

        .bonusDescription {

            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.32px;
            color: var(--text-base-secondary);
        }

        .bonusImg {
            position: absolute;
            bottom: 0;
            right: 0;
            max-width: 50%;
            max-height: 50%;
        }
    }

    .bonusCardActive {
        border: 2px solid var(--border-accent-brand-accent);

    }
}


}