@use 'src/common/styles/mixins';
@use 'src/common/styles/articlesMixins';

.page {
    .pageContainer {
        @include mixins.gameGroup();

        .nameContainer {
            display: flex;
            gap: var(--standart-block-padding);
            margin-bottom: var(--standart-block-padding);

            .articleTitle {
                @include mixins.title30();
            }
        }

        .body {
            @include articlesMixins.body();
        }


    }

    .imageContainer {
        @include mixins.flex($justifyContent: center);
        border: 1px solid var(--border-base-default);
        background: var(--background-modal-secondary);
        border-radius: var(--radius-24);
        margin-top: var(--standart-block-padding);
        overflow: hidden;
        

        img {
            max-width: 100%;
        }
    }
}

