.footer {
    padding: 40px 0;
    margin-top: auto;

    .footerContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;

        .data {
            .logoContainer {
                margin-bottom: 22px;
            }

            .text {
                max-width: 352px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.28px;
            }
        }

        .accordions {
            display: flex;
            gap: 32px;

            .accordionRoot {
                background-color: transparent;
                color: #fff;
                box-shadow: none;

                .accordionSummary {
                    background-color: var(--background-surface-subtle-primary);
                    border-radius: var(--radius-12);
                    width: 233px;
                    margin-bottom: 24px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px
                }

                .accordionDetails {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 0 0 0 12px;

                    .link {
                        color: inherit;
                        text-decoration: none;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.32px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .bottomContainer {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        
        .bottomText {
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: -0.32px;
        }

        .socialIcons {
            display: flex;
            gap: 12px;

            img {
                cursor: pointer;
            }
        }
    }


}
