@mixin title() {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.4px;
}

@mixin body() {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin: 0;
}

@mixin meta_description() {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;
}

@mixin contentText() {
    max-width: 477px;
    max-height: 144px;
}

@mixin contentImage() {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right;
    }
}