@use 'src/common/styles/mixins';

.moneyContainer {
    --chevron-size-offset: calc((var(--chevron-size) / 2) * -1 );

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--text-base-primary);
    cursor: auto;

    .type {
        color: var(--text-base-quarternary);
        @include mixins.moneyType();
    }

    .amount {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .circular {
            color: var(--text-base-primary);
        }

        .chevronContainer { /*styles for ListBalancesDropdown offset*/
            .chevron {
                cursor: pointer;
                position: relative;
                width: var(--chevron-size);
                height: var(--chevron-size);
            }

            .disableCursorPointer {
                cursor: auto;
            }

            .childrenContainer {
                position: absolute;
                right: calc(var(--list-balances-dropdown-width) / 2);
                transform: translateX(var(--chevron-size-offset)) translateY(10px);
                z-index: 1;
            }
        }
    }

    .disableCursorPointer {
        cursor: auto;
    }
}

