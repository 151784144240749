@use 'src/common/styles/mixins';

.itemContent {
    display: flex; // Two columns layout
    gap: var(--gap-question);
    @include mixins.gameGroup($margin-top: null, $padding: var(--content-grid-padding));

    .itemNameTitles {
        width: 366px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: var(--gap-question);
    }

    .nameTitle {
        padding: 12px 16px;
        background: var(--background-modal-primary);
        border: 1px solid var(--border-base-default);
        cursor: pointer;
        text-align: left;
        border-radius: var(--radius-16);
        transition: background 0.3s;
        @include mixins.elementName();
    }

    .active {
        background: var(--background-modal-tetriary); // Highlight selected item
    }

    .itemDescription {
        flex: 1;  // Right column for the answer
        @include mixins.flex($direction: column);
        gap: var(--standart-block-padding);
        padding: var(--standart-block-padding);
        border-radius: var(--radius-24);
        background: var(--background-surface-default);
        height: fit-content;

        .subHeaderContainer {
            @include mixins.flex($justifyContent: space-between);
            width: 100%;

            .button {
                @include mixins.flex($alignItems: center);
                gap: 6px;
            }
        }
    }

    .faqAnswerText {
        @include mixins.sidebarItemFontStyles();
    }


}