@use '../../../common/styles/mixins';

.winners {

    --button-width: 133px;
    --element-width: 372px;

    margin-top: var(--margin-between-block); // added because not styled of Providers

    .title {
        margin-bottom: 40px;
    }

    .element {
        display: flex;
        align-items: center;
        padding: 16px;
        min-width: var(--element-width);
        max-width: calc(var(--element-width) + 10%);
        @include mixins.carouselElement();

        .gameImage {
            width: 191px;
            min-width: 191px;
            height: 128px;
            margin-right: 16px;
            border-radius: var(--radius-16);
            align-self: center;
        }

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            color: var(--text-base-primary);

            .playerContainer {
                display: flex;

                .icon {
                    @include mixins.userIcon24();
                }


                .player {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.32px;
                }
            }

            .currencyContainer {
                display: flex;
                align-items: center;
                margin-top: 6px;
                margin-bottom: 4px;

                .totalAmount {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.4px;
                }
            }

            .providerName {
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.24px;
            }

            .totalAmount {
                font-size: 20px;
                color: var(--text-accent-brand-secondary);
            }

            .button {
                margin-top: 10px;
                min-width: var(--button-width);
                max-width: calc(var(--button-width) + 10%);
            }
        }
    }
}

.currency {
    min-width: 24px;
    height: 24px;
}

.currencyCode {
    font-size: 20px;
    color: var(--text-accent-brand-secondary);
}

.carousel {
    @include mixins.carouselSettings();
}

