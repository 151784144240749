@use 'src/common/styles/mixins';

.faqContainer {                            
    --gap-question: 16px;
    height: 100%;

    .pageHeader {
        margin-bottom: var(--content-grid-padding);
    }
}


