@use '../styles/mixins';

.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
}

.name {
    display: flex;
    align-items: center;
    padding: 0;
}

.itemsContainer {
    @include mixins.SidebarElementsContainer();

    .item {
        display: flex;
        align-items: center;
        height: var(--sidebar-item-height);
        text-wrap: nowrap;
        border-radius: var(--radius-12);
        padding: 12px;

        &:hover {
            @include mixins.itemHover();
        }
    }

    .trophy {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

}


.group {
    @include mixins.blockBottomMargin();
}

.checkboxHint {
    color: var(--text-base-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
}

.userIcon {
    height: 40px;
    min-width: 40px;
    max-width: 40px;
}

.accountElement {
    padding: var(--standart-block-padding);
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-24);
    display: flex;
    flex-direction: column;

    .header {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
    }
}

.button {
    text-transform: none;
    font-size: 14px;
    border: none;
    font-weight: 600;
    font-family: var(--font-family), sans-serif;
    cursor: pointer;
    box-shadow: none;
    transition: background-color 0.2s ease, transform 0.1s ease;
    text-decoration: none;
    @include mixins.flexCenter();
    color: var(--text-base-primary);
    text-wrap: nowrap;

}

.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.profileRoundedTab {
    border-radius: 0 0 var(--radius-40) var(--radius-40);
    border-top: 2px solid var(--border-base-tetriary);
    background-color: var(--background-surface-elevation-1);
    padding: 24px;
}

.profileRoundedTabNoPadding {
    @extend .profileRoundedTab;
    padding: 0;
    margin-bottom: 40px;
    overflow: hidden;
}

.inputSelectLabel {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}