@use 'src/common/styles/articlesMixins';

.contentBlockContainer {
    background-color: var(--background-surface-elevation-1);
    border-radius: var(--radius-40);
    padding: 0 40px;
    height: 468px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .contentWrapper {
        display: flex;
        width: 100%;

        .contentText {
            @include articlesMixins.contentText();

            color: var(--text-base-secondary);
            padding: 40px 0;

            .title, h4 {
                color: var(--text-base-primary);
            }


            .title {
                @include articlesMixins.title();
                padding-bottom: 32px;
                margin: 0;
            }

            .body {
                @include articlesMixins.body();
                color: var(--text-base-secondary);
                padding-bottom: 32px;

                h4 {
                    @include articlesMixins.meta_description();
                }
            }

            .contentLink {
                display: inline-block;
                padding: 10px 20px;
                background-color: #d7d7d7;
                color: black;
                text-decoration: none;
                border-radius: 5px;
                font-weight: bold;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #bfbfbf;
                }
            }
        }

        .contentImage {
            @include articlesMixins.contentImage();
        }

    }
}



.linkWrapper {
    text-decoration: none;
    color: black;
}




