.signup {
    position: relative;
    color: var(--text-base-secondary);
    overflow: hidden;

    .signupFormInputs {
        margin: var(--auth-modal-big-margin) 0;
    }

    .smallMarginBottom {
        margin-bottom: var(--auth-modal-small-margin);
    }

    .bigMarginBottom {
        margin-bottom: var(--auth-modal-big-margin);
    }

    .subscriptionBlock {
        margin-bottom: 8px;
    }
}

.outsideButtonsBlock {
    position: absolute;
    top: calc(var(--modal-action-button-size) + var(--auth-modal-big-margin));
    right: 0;
    transform: translateX(100%); /*on full size of inner item*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.extraBonusTitle {
   text-align: center;
   padding-bottom: var(--auth-modal-big-margin);
}