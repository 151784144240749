.selectInput {
    .select {
        background-color: var(--background-input-normal);
        border: 1px solid var(--border-action-normal);
        border-radius: var(--radius-12);
        color: var(--text-base-primary);
        width: 100%;
        height: 40px;
    }



    .chevronContainer {
        pointer-events: none; /* to make chevron clickable */
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;

        .currencyName {
            margin-left: 4px;
        }

    }
}

.optionContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    width: 100%;

    img {
        width: 20px;
        height: 20px;
    }

    .checkIcon {
        position: absolute;
        right: 0;
    }

}




