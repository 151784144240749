:global {
    .rdp-root {
        //--day-width: 22px;

        //--rdp-day_button-width: var(--day-width);
        //--rdp-day_button-height: var(--day-width);
        //--rdp-day-width: var(--day-width);
    }

    .rdp-selected {
        .rdp-day_button {
            border: 1px solid var(--border-action-brand-1);
            border-radius: var(--radius-8);
        }
    }

    .rdp-dropdown:focus-visible ~ .rdp-caption_label {
        outline: none;
    }
}

.inputSingleContainer {
    max-width: 400px;
}

.pickerContainer {
    --chevron-color: #F6F6F6;

    .calendarFooter {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 12px;

        .button {
            flex: 1;
        }
    }

    .root {
        width: var(--input-width);
        position: fixed;
        background-color: var(--background-modal-primary);
        margin-top: 4px;
        box-sizing: border-box;
        border-radius: 16px;
        overflow: hidden;
        padding: 16px;
        border: 1px solid var(--border-base-default);
    }

    .month_grid {
        width: 100%;
        max-width: var(--input-width);
        background-color: var(--background-modal-primary);
        border-radius: 0 0 var(--radius-16) var(--radius-16);
        box-sizing: border-box;
        margin-top: 16px;
    }

    .months {
        max-width: 100%;
    }

    .dropdown {
        background-color: var(--background-modal-primary);
    }

    .caption_label {

    }

    .month_caption {
        color: var(--text-base-primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        height: unset;
    }

    .chevron {
        fill: var(--chevron-color);
    }

    .nav {
        height: unset; // remove nav button styles
        display: flex;
        gap: 8px;
        top: 16px;
        right: 16px;
    }

    .button_previous, .button_next {
        height: unset; // remove nav button styles
        width: unset;
    }

    .dropdown::-webkit-scrollbar {
        width: 5px;
        border-radius: var(--radius-16);
    }

    .dropdown::-webkit-scrollbar-track {
    }

    .dropdown::-webkit-scrollbar-thumb {
        background-color: var(--chevron-color);
        border-radius: 10px;
    }


    .day, .weekday {
        color: var(--text-base-secondary);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.28px;
        text-align: center;
    }

    .day {
        button {
          margin: 0 auto;
        }
    }

    .today {
        color: var(--border-action-brand-1);
    }
}









