@use '../../../common/styles/mixins';



.widgetContainer {
    .groupContainer {
        @include mixins.blockBottomMargin();

        .titleContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 28px;

            .titleAndControls {
                display: flex;
                align-items: center;

                h5 {
                    @include mixins.h5Group();
                }

                .sliderControls {
                    margin-left: 32px;
                    display: flex;
                    gap: 10px;
                }
            }
        }

        .gamesContainer {

        }
    }
}

.carousel {
    @include mixins.carouselSettings();
}