@use '../styles/mixins';

.scrollButton {
    min-width: 40px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: (var(--radius-100));
    background: var(--background-surface-subtle-primary);
    @include mixins.flexCenter();

    &:hover {
        background: var(--background-button-glass-hover);
    }

    &:active {
        background: var(--background-button-glass-pressed-correct);
    }
}