:root {
    --standart-block-padding: 24px;

    --font-family: Inter;
    --background-button-primary-hover: #0ACF1E;
    --background-button-glass-hover: rgba(255, 255, 255, 0.24);
    --background-button-primary-pressed-correct: #00A210;
    --background-button-glass-pressed-correct: rgba(255, 255, 255, 0.3);
    --sidebar-item-height: 48px;
    --sidebar-items-gap: 16px;
    --sidebar-children-menu-padding: 12px;
    --sidebar-children-items-gap: 8px;
    --content-grid-padding: 40px;
    --margin-between-blocks: 80px;
    --list-balances-dropdown-width: 380px;
    --chevron-size: 24px;
    --navbar-height: 80px;
    --game-group-padding: var(--standart-block-padding);
    --auth-modal-big-margin: var(--standart-block-padding);
    --auth-modal-small-margin: 16px;
    --modal-action-button-size: 40px;
    --form-column-spacing: 10px;
    --background-tabs-normal-active: rgba(255, 255, 255, 0.12);
    --text-accent-green-primary: #00B212;
    --background-chip-inverted: #141414;
    --background-input-disabled-correct: #1F1F1F;
    --border-action-disabled-correct: rgba(255, 255, 255, 0.05);
}