@use '../styles/mixins';

.titleContainer {
    display: flex;
    align-items: center;
    height: 44px;

    .iconContainer {
        width: 44px;
        height: 44px;
        @include mixins.flexCenter();
        background-color: var(--background-surface-subtle-secondary);
        border-radius: var(--radius-12);
        padding: 6px;

        .winners_icon {
            width: 32px;
            height: 32px;
        }
    }

    h5 {
        margin-left: 12px;
        @include mixins.h5Group();
        margin-bottom: 0;
    }
}

.marginBottom {
    margin-bottom: 40px;
}