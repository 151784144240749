@use '../../../common/styles/mixins';

.component {
    position: relative;
    
    .tippy {
        position: absolute;
        bottom: -13px;
        right: 50%;
        transform: translateX(50%)
    }

    .menu {
        padding: 16px;
        right: 50%;
        transform: translateX(50%) translateY(12px);
        background-color: var(--background-modal-secondary);
        width: 351px;
        border-radius: var(--radius-24);
        border: none;


        .linksContainer {
            display: flex;
            gap: 8px;
            margin-top: 8px;

            .link {
                color: var(--text-base-primary);
                font-size: 12px;
                padding: 8px 12px;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }


}