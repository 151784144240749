@use 'src/common/styles/mixins';

.securityElement {
    background-color: var(--background-surface-elevation-2);
    width: 366px;
    height: 298px;
    padding: var(--standart-block-padding);
    border-radius: var(--radius-24);
    @include mixins.flex($direction: column, $justifyContent: space-between);

    .button {
        width: 100%;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; 
        letter-spacing: -0.48px;
        margin-top: 16px;
    }

    .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px
    }
}