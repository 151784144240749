.auth-layout {
    background-color: #f8f9fa;
}

.centeredCard {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.card-body {
    display: flex;
    flex-direction: column;
}

.leftContent {
    color: var(--text-base-primary);
    border-radius: var(--radius-40) 0 0 var(--radius-40);
    background-image: var(--background);
    height: 100%;
    opacity: 0.9;
    padding: 63px 16px 0;
    background-size: cover;

    .text {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
        text-align: center;
        opacity: 1;
    }
}

.rightContent {
    padding: 1.5rem;
    width: 100%;
}

.btnInBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--sidebar-item-height);
    height: var(--sidebar-item-height);
    background-color: var(--background-button-glass);
    border-radius: 0 12px 12px 0;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s;   
    overflow: hidden;

    img {
        --bonus-size: 32px;
        width: var(--bonus-size);
        max-height: var(--bonus-size);
        height: var(--bonus-size);
    }
}

.btnInBlock.active {
    background: var(--background-button-primary); // green background on active state
}

.btnInBlock img {
    height: 100%;
    object-fit: cover; /* Изображение заполняет блок, сохраняя пропорции */
    border-radius: 50%; /* Делаем изображение круглым, если требуется */
}

.btnСlose, .btnPrev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: #fff;
    background-color: rgba(197, 197, 197, 0.5);
    border: none;
    border-radius: 50%;
    position:absolute;
    font-size: 2rem;
    cursor: pointer;
    transition: opacity 0.3s;
}

.btnСlose{
    right: -4rem;
}
.btnPrev {
    left: -4rem;
}
