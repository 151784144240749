@use 'src/common/styles/mixins';
@use 'src/common/styles/articlesMixins';

.pageList {

    .pageHeader {
        margin-bottom: var(--content-grid-padding);
    }

    .articlesContainer {
        @include mixins.gameGroup();
        @include mixins.flex($direction: column);
        gap: var(--standart-block-padding);
        //max-height: 468px;
        //overflow: hidden;

        .article {
            width: 100%;
            display: flex;
            gap: 48px;
            border: 1px solid var(--border-base-default);
            background: var(--background-modal-secondary);
            border-radius: var(--radius-24);
            //padding: var(--content-grid-padding);
            height: 468px;
            overflow: hidden;

            .contentText {
               @include articlesMixins.contentText();
                padding: 40px 0 40px 40px;
                
                width: 100%;
                
                .h1 {
                    @include articlesMixins.title();
                }

                .meta_description {
                    @include articlesMixins.meta_description();
                    margin-top: 32px;
                }

                .body {
                    @include articlesMixins.body();
                    color: var(--text-base-secondary);
                    margin-top: 16px;
                    padding-bottom: 32px;
                }
            }

            .contentImage {
                @include articlesMixins.contentImage();
            }
        }
    }



    .pagination {
        margin-top: 40px;
        justify-content: center;
    }
}

