@use 'src/common/styles/mixins';

.login {
    color: var(--text-base-secondary);

    --left-part : 240px;
    --right-part: 496px;

    height: 436px;
    width: fit-content;

    .gridContainer {
       display: grid;
       grid-template-columns: var(--left-part) var(--right-part);
        height: 100%;

        .leftContent {
            height: 100%;
        }

       .rightContent {
       }
    }

    .formInputs {
       display: flex;
       flex-direction: column;
       gap: var(--auth-modal-small-margin);
       margin: var(--auth-modal-big-margin) 0;
    }

    .forgotPasswordContainer {
        margin-top: var(--auth-modal-small-margin);
        cursor: auto;
        display: flex;
        justify-content: center;

        .forgetText {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
            cursor: pointer;
        }
    }


}