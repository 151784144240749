@use 'src/common/styles/mixins';

.termsContainer {
    --gap-question: 16px;
    height: 100%;

    .termsTop {
        @include mixins.flex($justifyContent: space-between, $alignItems: center);
        margin-bottom: var(--content-grid-padding);

        .termsLeft {
            @include mixins.flex($direction: column, $justifyContent: center);
            height: 100%;
            gap: var(--standart-block-padding);
        }

        .updated {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: var(--text-base-secondary);
        }

        .termsText {
            max-width: 631px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.28px;
        }
    }


}


