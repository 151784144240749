@use '../../../common/styles/mixins';

.gameGroupContainer {

    .categoryName {
        text-transform: capitalize;
    }

    .gameGroup {
       @include mixins.gameGroup();

        .nameContainer {
            display: flex;
            padding: var(--game-group-padding) 0;
        }


        .categoryName {
            @include mixins.gameCategoryName();
        }

        .paginationContainer {
            margin-top: 40px;
        }
    }
}

