@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex($direction: row, $justifyContent: flex-start , $alignItems: flex-start, $wrap: nowrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justifyContent;
    align-items: $alignItems;
    flex-wrap: $wrap;
}

@mixin carouselElement() {
    background-color: var(--background-surface-elevation-2);
    border-radius: var(--radius-24);
}

@mixin carouselSettings() {
    display: flex;
    gap: 24px;
}

@mixin h5Group() {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.72px;
}

@mixin sidebarItemFontStyles() {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
}

@mixin blockBottomMargin() {
    margin-bottom: 80px;
}

@mixin ColorQuestionsBlockTitle() {
    color: var(--text-base-primary);
    font-size: 14px;
}

@mixin ColorQuestionsBlockSubtitle() {
    color: var(--text-base-secondary);
    font-size: 12px;
}

@mixin SidebarElementsContainer() {
    display: flex;
    flex-direction: column;
}

@mixin itemHover() {
    background-color: var(--background-tabs-normal);
    color: var(--text-accent-brand-primary);
    cursor: pointer;
}

@mixin userIcon24() {
    height: 24px;
    min-width: 24px;
    margin-right: 8px;
}

@mixin gameGroup($margin-top: 40px, $padding: var(--game-group-padding)) {
    @if $margin-top != null {
        margin-top: $margin-top;
    }
    padding: $padding;
    background-color: var(--background-surface-elevation-1);
    border-radius: var(--radius-40);
}

@mixin gameCategoryName() {
    @include title30();
    text-transform: capitalize;
    margin-left: 24px;
}

@mixin buttonGlassHoverActive() {
    &:hover {
        background-color: var(--background-button-glass-hover);
    }

    &:active {
        background-color: var(--background-button-glass-pressed-correct);
    }
}

@mixin input() {
    height: 40px;
    border-radius: var(--radius-12);
    border: 1px solid var(--border-action-normal);

    &:focus {
        outline: none;
        box-shadow: none;
    }

}

@mixin hintVariant {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: var(--text-base-secondary);
}

@mixin profileText() {
    
}

@mixin moneyType() {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.24px;
    margin-bottom: 0;
    padding-bottom: 0;
}

@mixin elementName {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}

@mixin title30() {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.6px;
}
