@use '../../../../common/styles/mixins';

@mixin commonModal() {
    position: relative;
    overflow: visible;
    background-color: var(--background-surface-default);
    margin: 0;
    border-radius: var(--radius-40);
}

.overlay {
    --button-margin: 8px;

    --modal-login-left-size: 240px;
    --modal-login-right-size: 480px;

    @mixin roundedButton() {
        position: absolute;
        background-color: var(--background-surface-subtle-primary);
        width: var(--modal-action-button-size);
        height: var(--modal-action-button-size);
        @include mixins.flexCenter();
        border-radius: 50%;
        cursor: pointer;
        border: none;
        top: 0;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-overlay-secondary);
    backdrop-filter: blur(5px); /* Размытие только для overlay */
    z-index: 1000;

    .modalDialog, .modalDialogAuth {
        box-shadow: none;
        max-width: unset;
    }

    .modalDialog {
        width: 480px;
        @include commonModal();
        padding: 24px;
        position: relative;
    }

    .modalDialogSecurity {
        background-color: var(--background-modal-primary);
        width: 400px;
    }

    .modalDialogAuth {
        @include commonModal();
    }

    .modalSuccess {
       padding: 0;
    }

    .btnClose {
        @include roundedButton();
        left: calc(100% + var(--button-margin));
        right: 0;
    }

    .btnPrev {
        @include roundedButton();
        left: calc(var(--modal-action-button-size) * -1 - var(--button-margin));
    }

    .modalBody {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .modalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 16px;
    }

    .modalFooter {
        display: flex;
        justify-content: flex-end;
    }

    .modalBody,
    .modalFooter,
    .modalHeader {
        //width: fit-content; /* Растягиваем все содержимое */
    }


}




