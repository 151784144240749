@use '../../../common/styles/mixins';

.sidebar {
    position: relative;
    z-index: 2;
    background-color: var(--background-surface-elevation-1);
    color: var(--text-base-secondary);
    width: 100%;
    padding: 24px 16px; // calculate to hide ChildrenMenu, maybe later will be better solution

    .sidebarItemsContainer {
        gap: var(--sidebar-items-gap);
    }

    .chevronContainer {
        display: flex;
        align-items: center;
        flex: 1;

        .title {
            flex: 1;
            @include mixins.sidebarItemFontStyles();
        }

        .chevronWrapper {
            display: flex;

            .chevron {
                margin-left: auto;
            }
        }

    }



    .wheelOfFortune {
        margin-bottom: calc(18px + var(--color-block-trophy-offset));
    }

    .freeBonuses {
    }

    .wheelOfFortune, .vipClub {
        margin-top: calc(18px + var(--color-block-trophy-offset));
    }

    .vipClub {
        margin-bottom: 40px;
    }

    .userIcon {
        @include mixins.userIcon24();
    }
}
